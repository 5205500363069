<template>
    <div class="brandDetail-content">
        <el-breadcrumb separator="/" class="student-breadcrumb">
            <el-breadcrumb-item :to="{ path: '/student/operationpromotion/index' }">店铺选品</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/student/operationpromotion/industrymarket' }">数据分析</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/student/operationpromotion/commoditylist' }">商品榜单</el-breadcrumb-item>
            <el-breadcrumb-item>品牌榜单</el-breadcrumb-item>
            <el-breadcrumb-item>品牌详情</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="page-title">品牌详情</div>
        <div class="goods-overview">
            <div class="title">商品概况</div>
            <div class="overview-content">
                <div class="oo-item">
                    <div class="oo-title">
                        <img src="../../../../assets/images/good-detail-brand.png" alt="">
                        <p>品牌排名</p>
                    </div>
                    <div class="oo-right">
                        <div class="item">
                            <p class="text">按成交金额排行</p>
                            <p class="num" v-if="brandDetail">{{brandDetail.money_ranking}}</p>
                        </div>
                        <div class="item">
                            <p class="text">按点击指数排名</p>
                            <p class="num" v-if="brandDetail">{{brandDetail.chick_ranking}}</p>
                        </div>
                    </div>
                </div>
                <div class="oo-item">
                    <div class="oo-title">
                        <img src="../../../../assets/images/good-detail-store.png" alt="">
                        <p>店铺商品</p>
                    </div>
                    <div class="oo-right">
<!--                        <div class="item">-->
<!--                            <p class="text">品牌店铺数</p>-->
<!--                            <p class="num">999,999,999</p>-->
<!--                        </div>-->
<!--                        <div class="item">-->
<!--                            <p class="text">动销店铺数</p>-->
<!--                            <p class="num">999,999,999</p>-->
<!--                        </div>-->
                        <div class="item">
                            <p class="text">动销商品数</p>
                            <p class="num" v-if="brandDetail">{{brandDetail.goods_num}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="oo-item">
                    <div class="oo-title">
                        <img src="../../../../assets/images/good-detail-yinliu.png" alt="">
                        <p>引流漏斗</p>
                    </div>
                    <div class="oo-right">
                        <div class="item">
                            <p class="text">浏览量</p>
                            <p class="num" v-if="brandDetail">{{brandDetail.all_view}}</p>
                        </div>
                        <div class="item">
                            <p class="text">成交件数指数</p>
                            <p class="num" v-if="brandDetail">{{brandDetail.all_conversion_num}}</p>
                        </div>
                        <div class="item">
                            <p class="text">点击指数</p>
                            <p class="num" v-if="brandDetail">{{brandDetail.all_click_num}}</p>
                        </div>
                    </div>
                </div>
                <div class="oo-item">
                    <div class="oo-title">
                        <img src="../../../../assets/images/good-detail-zhuanhua.png" alt="">
                        <p>成交转化</p>
                    </div>
                    <div class="oo-right">
                        <div class="item">
                            <p class="text">成交金额指数</p>
                            <p class="num" v-if="brandDetail">{{brandDetail.all_amount}}</p>
                        </div>
                        <div class="item">
                            <p class="text">成交单量指数</p>
                            <p class="num" v-if="brandDetail">{{brandDetail.all_conversion_num}}</p>
                        </div>
                        <div class="item">
                            <p class="text">成交转化率</p>
                            <p class="num" v-if="brandDetail">{{brandDetail.conversion}}%</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-title">趋势图</div>
        <div class="trend-wrapper">
            <div class="trend-category">
                <div class="cate-left">
                    <p>品牌排名</p>
<!--                    <p>店铺商品</p>-->
                    <p>引流漏斗</p>
                    <p>成交转化</p>
                </div>
                <div class="cate-right">
                    <el-checkbox-group v-model="checkList" :max="4">
                        <el-checkbox :label="checkItem" v-for="checkItem in categoryList" :key="checkItem"></el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
            <div class="choose-number">
                已选择:
                <span class="number">
                    <span>{{checkList.length}}</span> / 4
                </span>
            </div>
            <div class="trend-content" id="trendECharts"></div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                brandId: Number(this.$route.query.id) || '',
                brandDetail: '',
                trendECharts: null,
                checkList: ['按成交金额排名', '浏览量', '成交单量指数', '成交客单价指数'],
                categoryList: [
                    '按成交金额排名', '按点击指数排名', '浏览量', '点击指数', '成交单量指数', '成交件数指数', '成交金额指数', '成交客单价指数', '成交转化率'],
                allSeries: [],
            }
        },
        mounted() {
            this.getBrandDetail()
            this.trendECharts = this.$echarts.init(document.getElementById('trendECharts'));
            this.getTrendECharts()

            let resizeTimer = null;
            window.onresize = () => {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(() => {
                    this.trendECharts.resize()
                }, 100)
            }
        },
        computed: {
            series() {
                return this.allSeries.filter(item => {
                    return this.checkList.includes(item.name)
                })
            }
        },
        watch: {
            series(val) {
                // console.log('seriesVal', val)
                this.getTrendECharts()
            }
        },
        methods: {
            // 获取品牌详情
            getBrandDetail() {
                this.$http.axiosGetBy(this.$api.storeSelectionBrandDetail, {id: this.brandId}, (res) => {
                    if (res.code === 200) {
                        this.brandDetail = res.data
                        this.allSeries = [
                            {
                                name: '按成交金额排名',
                                type: 'line',
                                data: this.brandDetail.money_ranking_arr,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#000",
                                    }
                                },
                            },
                            {
                                name: '按点击指数排名',
                                type: 'line',
                                data: this.brandDetail.chick_ranking_arr,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#d6006a",
                                    }
                                },
                            },
                            // {
                            //     name: '品牌店铺数',
                            //     type: 'line',
                            //     data: ['980', '190', '3568', '940', '490', '1093', '645', '860', '790', '3520', '390', '420'],
                            //     smooth: true,
                            //     itemStyle: {
                            //         normal: {
                            //             color: "#00ffcc",
                            //         }
                            //     },
                            // },
                            // {
                            //     name: '动销店铺数',
                            //     type: 'line',
                            //     data: ['420', '490', '1093', '980', '190', '3568', '940', '645', '860', '790', '3520', '390'],
                            //     smooth: true,
                            //     itemStyle: {
                            //         normal: {
                            //             color: "#baff00",
                            //         }
                            //     },
                            // },
                            // {
                            //     name: '动销商品数',
                            //     type: 'line',
                            //     data: ['790', '3520', '390', '490', '1093', '860', '420','980', '190', '3568', '940', '645'],
                            //     smooth: true,
                            //     itemStyle: {
                            //         normal: {
                            //             color: "#00ffcc",
                            //         }
                            //     },
                            // },
                            {
                                name: '浏览量',
                                type: 'line',
                                data: this.brandDetail.view_num,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#FFAC76",
                                    }
                                },
                            },
                            {
                                name: '点击指数',
                                type: 'line',
                                data: this.brandDetail.chick_data,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#ffea00",
                                    }
                                },
                            },
                            {
                                name: '成交单量指数',
                                type: 'line',
                                data: this.brandDetail.transaction_volume,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#26C6DA",
                                    }
                                },
                            },
                            {
                                name: '成交件数指数',
                                type: 'line',
                                data: this.brandDetail.transaction_volume,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#0084FF",
                                    }
                                },
                            },
                            {
                                name: '成交金额指数',
                                type: 'line',
                                data: this.brandDetail.transaction_amount,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#E43C3E",
                                    }
                                },
                            },
                            {
                                name: '成交客单价指数',
                                type: 'line',
                                data: this.brandDetail.unit_price,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#00d619",
                                    }
                                },
                            },
                            {
                                name: '成交转化率',
                                type: 'line',
                                data: this.brandDetail.transaction_conversion,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#4600d6",
                                    }
                                },
                            },
                        ]
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 趋势图
            getTrendECharts() {
                this.trendECharts.setOption({
                    xAxis: {
                        type: 'category',
                        data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
                        boundaryGap: false,
                        axisLabel: {
                            textStyle: {
                                color: '#666'
                            },
                            align: 'center'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            show: false,
                            // textStyle: {
                            //     color: '#666'
                            // }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        },
                        backgroundColor: '#fff',
                        textStyle: {
                            color: '#666',
                        },
                        borderColor: '#DCDCDC',
                        borderWidth: 2,
                        formatter: (params) => {
                            let res = params[0].name;
                            for (let i = 0; i < params.length; i++) {
                                if (params[i].seriesName === '成交转化率') {
                                    res += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value + '%';
                                } else {
                                    res += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value;
                                }
                            }
                            return res
                        }
                    },
                    legend: {
                        icon: 'circle',
                        // data: this.checkList,
                        bottom: 0,
                        selectedMode: false
                    },
                    grid: {
                        top: 10,
                        left: 10,
                        right: 20,
                        bottom: 80,
                    },
                    series: this.series
                }, true);
            },
        }
    }
</script>

<style scoped lang="scss">
    .page-title {
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333;
        margin: 20px 0;
        line-height: 1;
    }
    .goods-overview {
        margin-top: 20px;
        background: #fff;
        padding: 20px;
        .title {
            font-size: 16px;
            line-height: 1;
        }
        .overview-content {
            margin-top: 20px;
            border: 1px solid #E5E5E5;
            display: flex;
            .oo-item {
                width: 50%;
                display: flex;
                align-items: center;
                .oo-title {
                    width: 112px;
                    height: 112px;
                    background: #F8F8F8;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 42px;
                        margin-bottom: 2px;
                    }
                }
                .oo-right {
                    width: 1%;
                    flex: 1;
                    display: flex;
                    .item {
                        width: 33.333333%;
                        padding-left: 20px;
                        p {
                            line-height: 1;
                            white-space: nowrap;
                        }
                        .text {
                            font-size: 16px;
                        }
                        .num {
                            font-size: 18px;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
    .trend-wrapper {
        background: #fff;
        padding: 20px;
        .trend-category {
            display: flex;
            border: 1px solid #E5E5E5;
            border-top: 0;
            .cate-left {
                width: 120px;
                background: #F8F8F8;
                border-top: 1px solid #E5E5E5;
                p {
                    line-height: 50px;
                    text-align: center;
                    font-size: 16px;
                    border-bottom: 1px solid #E5E5E5;
                    margin: 0;
                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }
            .cate-right {
                width: 1%;
                flex: 1;
                .el-checkbox-group {
                    display: flex;
                    flex-wrap: wrap;
                    .el-checkbox {
                        margin-right: 0;
                        line-height: 50px;
                        padding-left: 48px;
                        width: 15%;
                        box-sizing: border-box;
                        border-top: 1px solid #E5E5E5;
                        &:nth-of-type(2), &:nth-of-type(4) {
                            width: 85%;
                        }
                        &:last-of-type {
                            width: 40%;
                        }
                        /*&:nth-of-type(5) {*/
                        /*    width: 70%;*/
                        /*}*/
                        /*&:nth-of-type(1), &:nth-of-type(2) {*/
                        /*    border-bottom: 1px solid #E5E5E5;*/
                        /*}*/
                    }
                }
            }
        }
        .choose-number {
            margin-top: 30px;
            font-size: 18px;
            .number {
                margin-left: 20px;
                span {
                    color: #0084FF;
                }
            }
        }
        .trend-content {
            margin-top: 30px;
            height: 300px;
        }
    }
</style>